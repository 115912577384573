import { useStaticQuery, graphql } from 'gatsby';
import * as React from 'react';
import styled from 'styled-components';

import Image from '../components/image';
import Layout from '../components/layout';
import SEO from '../components/seo';

const IdeaPage = () => {
  const data = useStaticQuery(graphql`
    query {
      allMarkdownRemark(filter: { frontmatter: { title: { eq: "idea" } } }) {
        edges {
          node {
            id
            html
            frontmatter {
              title
            }
          }
        }
      }
    }
  `);
  const { node } = data.allMarkdownRemark.edges[0];

  return (
    <Layout path="/idea">
      <SEO
        title="企業理念"
        description="株式会社アールエムシーは、砂利、砂などの資材の採掘及び輸出入、販売及び仲介、運送のコンサルタント業務から広範囲にわたる建築・土木工事に至るまで幅広く事業を展開している企業です。"
      />
      <Wrapper>
        <Content dangerouslySetInnerHTML={{ __html: node.html }} />
        <ImageWrapper>
          <Image filename="idea-main.png" alt="企業理念" />
        </ImageWrapper>
      </Wrapper>
    </Layout>
  );
};

const Wrapper = styled.div`
  display: flex;
  padding: 40px 40px 0;
`;

const Content = styled.div`
  width: 450px;
  margin-right: 20px;
  font-size: 14px;
  line-height: normal;
  ol {
    margin: 16px 0;
    padding-left: 40px;
    list-style-type: decimal;
  }
`;

const ImageWrapper = styled.div`
  flex: 1;
`;

export default IdeaPage;
